<template>
  <GoBack />
  <div class="publish-forum">
    <div class="editor">
      <el-card>
        <el-form :model="formInline" class="el-my-form">
          <el-form-item required class="form-title">
            <el-input
              v-model="formInline.title"
              placeholder="请输入标题"
              maxlength="40"
              show-word-limit
              id="title"
              @input="showDialog"
              @keydown.enter="contentFocus"
              @focus="optDisabled"
              @blur="showOptions"
            ></el-input>
          </el-form-item>
          <vue-ueditor-wrap
            v-model="formInline.content"
            :config="editorConfig"
            editor-id="editorUe"
            @ready="readyEditor"
            ref="editorUe"
          ></vue-ueditor-wrap>
          <div class="operate-footer">
            <div class="oper-left">
              <el-form-item label="论坛" required>
                <el-select v-model="formInline.cateId" placeholder="选择论坛">
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in cateList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分类" v-if="formInline.cateId && tagList?.length" class="tag-list">
                <el-select v-model="formInline.tagId" placeholder="选择分类">
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in tagList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="check-share" v-if="!isEdit">
                <el-checkbox
                  class="share-to-blog"
                  v-model="formInline.type"
                  label="将帖子分享至微博"
                  name="type"
                ></el-checkbox>
              </el-form-item>
            </div>
            <div>
              <el-form-item>
                <el-button
                  class="pub-forum"
                  :disabled="btnDisabled"
                  @click="onSubmit"
                  >{{isEdit ? '保存编辑' : '发布帖子'}}</el-button
                >
              </el-form-item>
            </div>
          </div>
          <template v-if="formInline.type">
            <div class="editor-share">
              <CommonEditor
                buttonText=""
                editorType="forum"
                placeholder="说说你分享的理由~"
                :limit="limit"
                :initInputContent="formInline.share_content"
                ref="commonEditorRef"
              >
              </CommonEditor>
            </div>
          </template>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { forumPublish, editForumApi } from '@/apis/forum.js'
import mySocket from '@/mixin/socket.js'
import { myToast } from '@/utils/dialogMsg.js'
import { getCookies } from '@/utils/auth.js'
import { getAtUserID } from '@/utils/tools'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'

export default {
  name: 'TinyEditor',
  components: { CommonEditor },
  setup () {
    const editorConfig = {
      UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径，可参考常见问题1
      serverUrl:
        process.env.VUE_APP_URL + 'ueditor/index' + '?token=' + getCookies('token'), // 上传文件url
      initialFrameHeight: '730',
      initialFrameWidth: 'auto',
      focus: false,
      autoFloatEnabled: true,
      autoHeightEnabled: false,
      insertorderedlist: {
        decimal: '',
        'lower-alpha': '', // 'a,b,c...'
        'lower-roman': '', // 'i,ii,iii...'
        'upper-alpha': '', // 'A,B,C'
        'upper-roman': '' // 'I,II,III...'
      },
      insertunorderedlist: {
        circle: '', // '○ 小圆圈'
        disc: '', // '● 小圆点'
        square: '' // '■ 小方块'
      },
      toolbars: [
        [
          'undo', // 撤销
          'redo', // 重做
          'bold', // 加粗
          'italic', // 斜体
          'forecolor', // 字体颜色
          'backcolor', //  背景颜色
          'link', // 超链接
          'underline', // 下划线
          'strikethrough', // 删除线
          'horizontal', // 分隔线
          'insertimage', // 多图上传
          'emotion', // 表情
          'insertvideo', // 视频
          'justifyleft', // 居左对齐
          'justifyright', // 居右对齐
          'justifycenter', // 居中对齐
          'justifyjustify', // 两端对齐
          'insertorderedlist', // 有序列表
          'insertunorderedlist', // 无序列表
          'lineheight', // 行间距
          'fontfamily', // 字体
          'fontsize', // 字号
          'paragraph', // 段落格式
          'inserttable',
          'deletetable',
          'insertparagraphbeforetable',
          'attachment'
        ]
      ]
    }

    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { sendToSocket } = mySocket()

    // 绑定跳转过来的分区
    const state = reactive({
      formInline: {
        user: '',
        cateId: Number(route.query.cateId || route.params.cate_id) || '',
        tagId: Number(route.query.tagId || route.params.tag_id) || '',
        type: false,
        title: '',
        content: '',
        share_content: '',
        at_user: [],
        contentNumber: Number(0)
      },
      cateList: computed(() => store.state.forum.pubData),
      tagList: computed(() => store.state.forum.pubData.find(item => item.id === state.formInline.cateId)?.tags),
      limit: '1200',
      btnDisabled: computed(
        () =>
          !(
            state.formInline.title &&
            state.formInline.content &&
            state.formInline.cateId > 0 &&
            (state.formInline.contentNumber
              ? state.formInline.contentNumber
              : 0) <= state.limit
          )
      ),
      ue: null,
      commonEditorRef: null,
      isEdit: false,
      post_id: undefined
    })
    watch(() => state.formInline.cateId, (newValue, oldValue) => {
      state.formInline.tagId = ''
    })
    function readyEditor (editorInstance) {
      state.ue = editorInstance
    }
    const postForm = ref(null)
    // 发布动态
    function onSubmit () {
      const submitObject = {
        cate_id: state.formInline.cateId,
        tag_id: (state.tagList.length && state.formInline.tagId) ? state.formInline.tagId : 0,
        content: state.formInline.content,
        from: 1,
        title: state.formInline.title,
        share: state.formInline.type ? 2 : 1,
        post_id: state.post_id
      }
      // 分享到动态，添加参数
      if (state.formInline.type) {
        submitObject.at_user = getAtUserID(
          state.commonEditorRef.inputContent.html
        )
        submitObject.image_url = state.commonEditorRef.getImageData().map(item => item.url).join('^')

        submitObject.share_content =
          state.formInline.title +
          '</br></br>' +
          (state.commonEditorRef.inputContent.html || '')
      }
      // 提交
      state.isEdit ? editForum(submitObject) : addForum(submitObject)
    }

    // 发布帖子
    const addForum = async (submitObject) => {
      const res = await forumPublish(submitObject)
      if (res.code === 0) {
        // 分享动态
        if (submitObject.share === 2) {
          // 微博小红点提示
          sendToSocket(undefined, { type: 1, feedID: res.data.feed_id })
          // @ta人
          sendToSocket(submitObject.at_user, { type: 4 })
        }

        myToast({ type: 'success', message: '发布成功' })
        state.formInline = {}
        router.push({
          name: 'articalDetail',
          params: { id: res.data.post_id }
        })
      } else {
        myToast({ type: 'error', message: res.msg })
      }
    }

    const editForum = async (submitObject) => {
      const res = await editForumApi(submitObject)
      if (res.code === 0) {
        myToast({ type: 'success', message: '修改成功' })
        state.formInline = {}
        router.push({
          name: 'articalDetail',
          params: { id: res.data.post_id }
        })
      } else {
        myToast({ type: 'error', message: res.msg })
      }
    }

    // 进入页面从vuex里面取数据
    onMounted(() => {
      state.formInline = store.state.forum.postInfo
      state.formInline.cateId = Number(route.query.cateId) || ''
      state.formInline.tagId = Number(route.query.tagId) || ''
      // 光标定位标题
      setTimeout(() => {
        document.querySelector('#title').focus()
      }, 100)
      // 判断是否是编辑帖子
      if (route.params.title) {
        const newValue = route.params
        state.formInline.cateId = Number(newValue.cate_id) || ''
        state.formInline.tagId = Number(newValue.tag_id) || ''
        state.formInline.title = newValue.title
        state.formInline.content = newValue.content
        state.post_id = newValue.articalId
        state.isEdit = true
      }
    })

    // 离开页面保存信息
    onBeforeRouteLeave(() => {
      store.commit('forum/setPostInfo', state.formInline)
    })
    function contentFocus () {
      state.ue.focus()
    }
    const optDisabled = () => {
      document.querySelectorAll('.edui-box').forEach(i => i.classList.add('edui-state-disabled'))
    }

    // 显示按钮
    const showOptions = () => {
      document.querySelectorAll('.edui-box').forEach(i => i.classList.remove('edui-state-disabled'))
    }

    // 字数限制显示
    const showDialog = () => {
      if (state.formInline.title.length >= 40) {
        myToast({ type: 'warning', message: '标题最多输入40个字符' })
      }
    }

    return {
      ...toRefs(state),
      postForm,
      onSubmit,
      router,
      store,
      editorConfig,
      readyEditor,
      contentFocus,
      optDisabled,
      showOptions,
      showDialog
    }
  }
}
</script>

<style lang="less">
.publish-forum {
  .pub {
    display: inline-block;
    padding: 0 20px;
    margin-left: 10px;
    border-bottom: 3px solid #259bd6;
    margin-bottom: 20px;
    .el-button {
      padding: 0;
    }
    span {
      color: #555555;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>
<style lang="less" scoped>
.back{
  width: 930px;
  margin:0 95px 8px;
}
.el-my-form {
  margin-top: 0 !important;
  position: relative;
  .form-title {
    z-index: 100;
    position: absolute;
    top: 50px;
    padding-top: 24px;
    background-color: #fff;
    :deep(.el-input__inner) {
      width: 900px;
      border: 0;
      font-weight: bold;
      font-size: 20px;
      color: @default-text-color;
      padding-left: 48px;
    }
    :deep(.el-input__count) {
      display: none;
    }
    :deep(.el-input__inner::-webkit-input-placeholder) {
      color: @default-text-color;
    }
  }
}
.editor {
  width: 932px;
  margin: 0 auto;
  .el-card {
    :deep(.el-card__body) {
      padding: 0 !important;
    }
  }
}
.el-form {
  margin-top: 20px;
  :deep(.el-form-item__content) {
    line-height: unset;
  }
  .el-checkbox {
    line-height: 40px;
  }
  .el-form-item__label,
  .el-checkbox__label {
    font-size: 16px;
    font-weight: 700;
  }
  .el-checkbox__label {
    width: 150px;
  }
}
:deep(.edui-editor) {
  z-index: 80 !important;
}
:deep(.edui-default .edui-editor-breadcrumb) {
  display: none;
}
.el-col-3 {
  text-align: right;
}
.operate-footer {
  display: flex;
  padding: 18px 24px;
  border-top: 1px solid #f1f1f1;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  background: white;
  .oper-left {
    display: flex;
  }
  .el-form-item {
    margin: 0;
  }
  .tag-list{
      margin-left: 31px;
    }
  .check-share {
    margin-left: 31px;
  }
  .el-select {
    :deep(.el-input) {
      width: 133px;
      height: 30px;
    }
    :deep(.el-input__inner) {
      border-radius: 32px !important;
      border-color: #f1f1f1 !important;
      color: @default-text-color !important;
      background: #f6f9fa !important;
    }
    :deep(.el-input__inner::-webkit-input-placeholder) {
      color: @default-text-color;
    }
    :deep(.el-input__suffix-inner i) {
      color: @default-text-color !important;
    }
  }

  :deep(.el-form-item__label),
  :deep(.el-form-item__content),
  :deep(.el-select),
  :deep(.el-checkbox__label),
  :deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
    color: @default-text-color !important;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background: @active-text-color;
    border-color: #ccc;
  }
  :deep(.el-checkbox__inner) {
    border-color: #ccc;
  }
}
.editor-share {
  padding: 0 23px 0 23px;
  position: relative;
  z-index: 101;
  :deep(.emoji-picker) {
    top: -180px;
    z-index: 100;
  }
}
.pub-forum {
  width: 133px;
  border-radius: 20px;
  background: #7972f0 !important;
  color: #fff;
  border: none;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #e4e3fc;
}
.el-select-dropdown__item.selected {
  color: @default-text-color;
  font-weight: normal;
}
.pub-forum.is-disabled,
.pub-forum.is-disabled:hover {
  color: #fff;
}
</style>
