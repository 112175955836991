import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installVant from './plugins/vant'
import installElementPlus from './plugins/element'
import installElementPlusIcon from './plugins/element-icon'
import 'dayjs/locale/zh-cn'
import '@/assets/less/common.less'
import '@/assets/icon/iconfont/iconfont.css'
import VueUeditorWrap from 'vue-ueditor-wrap'
import lazyPlugin from 'vue3-lazy'
import { xssChange } from '@/utils/filterHtml'
import directives from '@/directives'

const loading = require('@/assets/emoji/icon.svg')

const app = createApp(App)
// 自定义指令
Object.keys(directives).forEach(key => {
  app.directive(key, directives[key])
})

installElementPlus(app)
installElementPlusIcon(app)
installVant(app)

// 注册全局组件
const importComponent = require.context('./components', false, /\.vue$/)
importComponent.keys().forEach((filePath) => {
  const component = importComponent(filePath).default
  app.component(component.name, component)
})
app.config.globalProperties.formatHtml = xssChange

app.use(store).use(router).use(VueUeditorWrap).use(lazyPlugin, {
  loading: loading,
  error: loading
}).mount('#app')
