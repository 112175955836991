<!--
 * @Descripttion: 投票弹窗页面
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-05-03 11:29:29
 * @LastEditors: tangjz
 * @LastEditTime: 2023-08-08 15:56:41
-->
<template>
  <div>
    <el-dialog v-model="store.state.vote.voteVisible" center :show-close="false" :close-on-click-modal="false">
      <CreateVoteDialog></CreateVoteDialog>
    </el-dialog>
    <slot name="clickButton"></slot>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import CreateVoteDialog from '@/views/layout/vote/component/CreateVoteDialog'

export default {
  components: {
    CreateVoteDialog
  },
  setup (props, { emit }) {
    const store = useStore()
    return {
      store
    }
  }
}
</script>

<style lang="less" scoped>
  :deep(.el-dialog) {
    width: 560px !important;
    min-height: 475px;
    border-radius: 6px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      width: auto;
    }
  }
</style>
