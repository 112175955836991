<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-22 15:05:27
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-09-12 18:15:41
-->
<template>
  <div class="common-editor">
    <!-- 输入框 -->
    <EditorInput
      ref="inputRef"
      @keyup.enter.ctrl.exact="handlerSubmit"
      v-model:inputContent="inputContent"
      :placeholder="placeholder"
      :isBlog="isBlog"
      :id="Number(key)"
      :initInputContent="initContent"
    >
    </EditorInput>
    <p :class="['limit-tip', count < 0 ? 'error' : '']" >
      <template v-if="count >= 0">
        还可以输入{{textLimit-count }}字
      </template>
      <template v-else>
        最多输入{{ textLimit}}字
      </template>
    </p>
    <ImageInfo
      :draggable="isBlog || props.editorType === 'forum'"
      ref="imageInfoRef"
      :id="Number(key)"
      :limit="imgLimit"
    ></ImageInfo>
    <template v-if="isBlog">
      <VideoInfo ref="videoInfoRef"></VideoInfo>
      <VoteInfo ref="voteInfoRef" :disabled="editBlogInfo.publish_status === 1"></VoteInfo>
      <FixedTime ref="fixedTimeRef" v-if="editBlogInfo.publish_status !== 1"></FixedTime>
    </template>
    <div class="footer-action">
      <EditorButtons
        :isImgBtnVisible="isImageBtnVisible"
        :isVideoBtnVisible="isBlog"
        :isVoteBtnVisible="isBlog"
        :isTimeBtnVisible="isVirtualUser && isBlog"
        :isTextVisible="isBlog"
        :isImgBtnDisabled="isImgBtnDisabled"
        :isVideoBtnDisabled="isVideoBtnDisabled"
        :isVoteBtnDisabled="isVoteBtnDisabled"
        :isTimeBtnDisabled="isTimeBtnDisabled"
        :id="Number(key)"
        :imgLimit="imgLimit"
        @updateContent="updateContent"
        @focusInput="focusInput"
        >
      </EditorButtons>
      <div>
        <!-- 发已发布状态微博-->
        <template v-if="isDraftBtnVisible">
          <el-button
            size="mini"
            class="submit-button draft-button"
            @click="handlerSubmit('save')"
            :disabled="!isBtnDisable">
            {{ draftButtonText  }}
          </el-button>
        </template>
        <template v-if="buttonText">
          <el-button
            size="mini"
            @click="handlerSubmit('submit')"
            class="submit-button"
            :disabled="!isBtnDisable"
            >
            {{ getButtonText() }}
          </el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>

import {
  computed,
  reactive,
  onMounted,
  onBeforeUnmount,
  nextTick,
  defineProps,
  defineEmits,
  toRefs,
  defineExpose
} from 'vue'

import { useStore } from 'vuex'
import { myToast } from '@/utils/dialogMsg.js'
import { getAtUserID } from '@/utils/tools'
import ImageInfo from '@/components/blog/ImageInfo.vue'
import VideoInfo from '@/components/blog/VideoInfo.vue'
import VoteInfo from '@/components/blog/VoteInfo.vue'
import FixedTime from '@/components/blog/FixedTime.vue'
import EditorButtons from '@/components/commonEditor/EditorButtons.vue'
import EditorInput from '@/components/commonEditor/EditorInput.vue'
import { FEEDFROM_TYPE } from '@/utils/const.js'

const props = defineProps({
  // blog 微博 editBlog 编辑微博 comment 评论 forum 转发帖子到微博 share 转发抽奖结果 okr评论
  editorType: { // 编辑器类型
    type: String,
    default: 'comment'
  },
  buttonText: { // 按钮文字
    type: String,
    default: '发布'
  },
  draftButtonText: {
    type: String,
    default: '保存草稿'
  },
  placeholder: { // 输入框默认文案
    type: String,
    default: ''
  },
  initInputContent: { // 初始化文案
    type: String,
    default: ''
  },
  limit: { // 输入框字数限制
    type: String,
    default: '800'
  }
})

const key = new Date().getTime()
const store = useStore()
const emit = defineEmits(['submit'])
const isVirtualUser = computed(() => store.getters['userInfo/isVirtualUser'])
// const isVirtualUser = true

// 需要传递的数据
const state = reactive({
  inputContent: { // 输入框
    html: '',
    text: ''
  },
  initContent: '', // 输入框初始化内容
  isBlog: computed(() => props.editorType === 'blog' || props.editorType === 'editBlog'), // 是否微博编辑
  isImageBtnVisible: computed(() => state.isBlog || props.editorType === 'comment' || props.editorType === 'forum'), // 是否可以上传图片
  inputRef: null,
  imageInfoRef: null,
  videoInfoRef: null,
  fixedTimeRef: null,
  inRequesting: false, // 是否在提交中
  textLimit: computed(() => props.limit), // 文本框最大输入框
  hasContent: false, // 是否有输入内容
  hasImgData: false, // 是否有上传图片
  imgLimit: computed(() => (state.isBlog || props.editorType === 'forum') ? (9 - state.imageInfoRef?.imageList.length) : 1), // 上传图片数量限制
  hasVideoData: false, // 是否有上传视频
  hasVoteData: computed(() => !!Object.keys(store.state.vote.voteInfos).length), // 是否发起投票
  isImgBtnDisabled: false, // 上传图片按钮是否可操作
  isVideoBtnDisabled: false, // 上传视频按钮是否可操作
  isVoteBtnDisabled: false, // 发起投票是否可操作
  isTimeBtnDisabled: false, // 设置定时按钮是否可操作
  isFixedBlog: false, // 是否定时发布,
  isDraftBtnVisible: computed(() => !state.isFixedBlog && (props.editorType === 'blog' || (state.editBlogInfo.id !== 0 && state.editBlogInfo.publish_status !== 1))),
  editBlogInfo: { // 编辑微博信息
    id: 0,
    publish_status: 0 // 微博状态,0-草稿1-普通2-定时动态
  },
  postTitle: '' // 帖子标题
})

const {
  inputContent,
  initContent,
  inputRef,
  imageInfoRef,
  videoInfoRef,
  fixedTimeRef,
  textLimit,
  imgLimit,
  isBlog,
  isImageBtnVisible,
  isImgBtnDisabled,
  isVideoBtnDisabled,
  isVoteBtnDisabled,
  isTimeBtnDisabled,
  editBlogInfo,
  isDraftBtnVisible
} = toRefs(state)

nextTick(() => {
  state.hasContent = computed(() => state.inputContent.html.replace(/((&nbsp;)+)/g, '').replace(/((&nbsp; )+)/g, '').trim().length > 0)
  state.hasImgData = computed(() => state.imageInfoRef?.imageList.length > 0 && state.imageInfoRef?.imageList.every(item => !!item.url))

  if (state.isBlog) {
    state.hasVideoData = computed(() => state.videoInfoRef?.videoUrl?.length > 0)
    // 上传了9张图片或上传视频、或者发起投票
    state.isImgBtnDisabled = computed(() => state.imageInfoRef?.imageList.length === 9 || state.hasVideoData || state.hasVoteData)
    // 有上传图片或者发起投票
    state.isVideoBtnDisabled = computed(() => state.hasImgData || state.hasVoteData)
    // 有上传图片或者视频,或者是编辑
    state.isVoteBtnDisabled = computed(() => state.hasImgData || state.hasVideoData || state.editBlogInfo.publish_status === 1)
    state.isFixedBlog = computed(() => state.fixedTimeRef?.timeVisible)
    // 定时按钮是否不可点击
    state.isTimeBtnDisabled = computed(() => state.editBlogInfo.publish_status === 1)
  }
})

onMounted(() => {
  window.addEventListener('beforeunload', beforeUnload)
  // 初始化本地草稿内容
  if (props.editorType === 'blog' && Object.keys(store.state.feedContentDraft).length) {
    initFeedContent(store.state.feedContentDraft)
  }
})

// 计算内容长度
const count = computed(() => {
  if (state.inputContent.text?.length <= Number(props.limit)) {
    return state.inputContent.text.replace(/((&nbsp;)+)/g, '').replace(/((&nbsp; )+)/g, '').trim().length
  } else {
    return Number(props.limit) - state.inputContent.text.length
  }
})

// 按钮是否可点击
const isBtnDisable = computed(() => {
  const inLimit = state.hasContent && count.value > -1 // 未超出字数限制
  if (state.isBlog) { // 微博
    return inLimit || state.hasImgData || state.hasVideoData || state.hasVoteData
  } else if (state.isImageBtnVisible) { // 带图片评论
    return inLimit || state.hasImgData
  } else { // 纯文字
    return inLimit
  }
})

const getButtonText = () => {
  const isFixedBlog = state.isFixedBlog
  const buttonText = props.buttonText
  return state.isFixedBlog && buttonText === '发布' ? '定时发布' : (buttonText === '保存' && !isFixedBlog) ? '发布' : buttonText
}

// 提交按钮逻辑
const handlerSubmit = (type) => {
  if (!state.inRequesting) {
    setTimeout(() => {
      // 获取请求参数
      const data = getParams(type)
      if (data) {
      // 设置请求状态
        state.inRequesting = true
        emit('submit', data, (code) => {
          state.inRequesting = false
          if (code === 0) {
          // 重置页面内容
            resetEditorInfo()
          }
        })
      } else {
        state.inRequesting = false
      }
    }, 0)
  }
}

// 获取参数
function getParams (type) {
  const atUserList = getAtUserID(state.inputContent.html)
  let data = {}
  // 图片信息
  data.images_url = getImageData().map(item => item.url).join('^')
  // 编辑，加上feed_id
  if (state.editBlogInfo.id !== 0) {
    data.feed_id = state.editBlogInfo.id
  }
  data.at_user = atUserList
  data.feed_content = state.inputContent.html
  // 发布动态，额外处理参数
  if (state.isBlog) {
    data.feed_from = FEEDFROM_TYPE.PC // pc端来源为1
    // 视频信息
    data.video_url = getVideoData()
    // 投票信息
    if (state.hasVoteData) {
      data = Object.assign(data, store.state.vote.voteInfos)
      data.vote_id = store.state.vote.voteInfos.id
      if (data.options.some(item => item.title)) {
        data.options = data.options.map(item => item.title)
      }
      delete data.id
    }

    if (state.isFixedBlog) { // 定时发布信息
      if (!state.fixedTimeRef.fixedTime) {
        data.publish_at = ''
        myToast({ type: 'error', message: '请选择未选择发布时间' })
        return false
      } else {
        // 定时发布的时间
        let beforeVote = false
        let beforeNow = false
        if (state.hasVoteData) { // 需早于投票时间
          beforeVote = state.hasVoteData && new Date(state.fixedTimeRef.fixedTime) > new Date(data.deadline_time)
        } else { // 需早于当前时间
          beforeNow = new Date(state.fixedTimeRef.fixedTime) < new Date()
        }
        if (beforeVote || beforeNow) {
          myToast({ type: 'error', message: `定时发布的时间需早于${beforeVote ? '投票截止' : '当前'}时间` })
          return false
        }
        data.publish_status = 2 // 定时动态
        data.publish_at = state.fixedTimeRef.fixedTime
      }
    }
  }
  // 发布状态，0 草稿动态 1 动态 2定时动态
  data.publish_status = data.publish_at ? 2 : type === 'submit' ? 1 : 0
  // 帖子,公告单独处理
  if (state.postTitle) {
    data.feed_content = `${state.postTitle}</br></br>${state.inputContent.html}`
  }
  return data
}

// 清空页面内容
function resetEditorInfo () {
  // 清空输入框
  state.inputRef.clearContent()
  state.inputContent = { html: '', text: '' }
  state.initContent = ''
  // 清空图片列表
  state.imageInfoRef.imageList = []
  if (state.isBlog) {
    // 清空视频信息
    state.videoInfoRef.videoUrl = ''
    // 清空投票信息
    store.commit('vote/setVoteInfo', {})
    // 定时设置
    if (state.fixedTimeRef) {
      state.fixedTimeRef.timeVisible = false
    }
    // 发微博 清空草稿箱内容
    if (props.editorType === 'blog') {
      store.commit('saveFeedContentDraft', {})
    }
  }
  state.inRequesting = false
}

const focusInput = () => {
  nextTick(() => {
    state.inputRef.focusInputEnd()
  })
}

const updateContent = (html, id) => {
  state.inputContent.html += html
  state.inputRef.getVal(undefined, id)
}

// 保存动态输入草稿
const setFeedContentDraft = () => {
  const data = {
    inputContent: state.inputContent,
    fileList: getImageData(),
    videoUrl: getVideoData(),
    fixedTime: getFixedTime()
  }
  store.commit('saveFeedContentDraft', data)
}

function getImageData () {
  return state.hasImgData ? state.imageInfoRef.imageList : []
}

function getVideoData () {
  return state.hasVideoData ? state.videoInfoRef?.videoUrl : ''
}
function getFixedTime () {
  return state.timeVisible ? state.fixedTimeRef?.fixedTime : ''
}

// 填充动态内容
const initFeedContent = (data) => {
  const { inputContent, fileList, videoUrl, fixedTime } = data
  state.inputContent.text = inputContent.text
  state.inputContent.html = inputContent.html
  focusInput()
  state.initContent = inputContent.html
  // 填充图片
  if (state.isImageBtnVisible) {
    state.imageInfoRef.imageList = fileList
  }
  // 填充视频和定时时间
  if (state.isBlog) {
    state.videoInfoRef.videoUrl = videoUrl
    if (fixedTime) {
      state.fixedTimeRef.timeVisible = true
      state.fixedTimeRef.fixedTime = fixedTime
    }
  }
}

// 编辑初始化
const editInit = (data) => {
  if (data) {
    state.editBlogInfo.id = data.id
    state.editBlogInfo.publish_status = data.publish_status
    const videoUrl = data.video_url
    let inputContent = {}
    const feedContent = data.feed_content
    // 转发帖子/公告到动态，需要把帖子标题截取出来
    if (data.post_id || data.announcement_id) {
      state.postTitle = feedContent.split('</br></br>')[0]
      const content = feedContent.split('</br></br>')[1]
      inputContent = { html: removeTopicText(content), text: content }
    } else {
      state.postTitle = ''
      inputContent = { html: removeTopicText(feedContent), text: feedContent }
    }

    const fixedTime = data.publish_at
    const fileList = []
    if (data.image_url.length > 0 && data.image_url.split('^').length > 0) {
      data.image_url.split('^').forEach(url => {
        fileList.push({ url })
      })
    }
    initFeedContent({ inputContent, fileList, videoUrl, fixedTime })
    // 填充投票信息
    if (data.vote_id) {
      store.commit('vote/setVoteInfo', data.vote)
    }
    console.log('editInit', data)
  } else {
    state.editBlogInfo = {
      id: 0,
      publish_at: 0
    }
    // 清空页面内容
    resetEditorInfo()
  }
}

// 去除话题的<a>标签内容
const removeTopicText = (value = '') => {
  // 正则表达式匹配带有话题的a标签
  let result = value.replace(/<a class="topicPubClick"[^>]*>(.*?)<\/a>/g, '$1')
  // 移除所有 HTML 实体（如 &nbsp;）
  result = result.replace(/&nbsp;/g, ' ')
  return result
}

// 刷新前进行草稿保存
const beforeUnload = () => {
  if (props.editorType === 'blog') {
    setFeedContentDraft()
  }
}

onBeforeUnmount(() => {
  setFeedContentDraft()
  window.removeEventListener('beforeunload', beforeUnload)
})

defineExpose({
  inputRef,
  inputContent,
  editInit,
  resetEditorInfo,
  getImageData,
  focusInput
})
</script>

<style lang="less" scoped>
.common-editor{
  padding: 8px 0;
  background: #fff;
  width: 100%;
}

.footer-action {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .submit-button {
    width: 85px;
    height: 32px;
    font-size: 14px;
    align-self: flex-end;
    transition: all 0.2s;
    background: @active-text-color;
    border-radius: 30px;
    color: white;
    user-select: none;
    border:none;
    padding: 10px 0px;
    &.draft-button{
      background-color: white;
      border:  1px solid @active-text-color;
      color: #333;
    }
    &.button-enter,
    &.button-leave-to {
      // zoom:0;
      margin-left: -40px;
      transform: scale(0, 0);
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
      // &:hover,&:focus {
      //   background-color: @active-text-color;
      // }
    }

    &:not([disabled]):active {
      background: @active-text-color;
    }
  }
}

.limit-tip {
  margin-top:5px ;
  font-size: 12px;
  color: @second-text-color;
  line-height: 16px;
  text-align: right;
  &.error{
    color: red;
  }
}

</style>
